<template>
  <div style="height: 100%">
    <DIDAccount />
  </div>
</template>

<script>
import { DIDAccount } from '@/components/console/Index.vue';

export default {
  components: {
    DIDAccount,
  },
};
</script>
